@charset "UTF-8";

@import './assets/iupics/styles/variables.css';
@import 'theme.scss';

/* You can add global styles to this file, and also import other style files */
.autocompleteRenderer,
.numberRenderer,
body .calendarRenderer.defaultDateCalendar.p-col-12 {
  padding: 1px 0;
}
body .numberRenderer input.iu-number-input {
  width: 100%;
}
/* .numberRenderer .number-input button:first-child, */
.autocompleteRenderer .p-inputtext,
.autocompleteRenderer i.icon-search-bolder,
.calendarRenderer .p-inputtext,
body .calendarRenderer .iconeCalendar,
body .numberRenderer input.iu-number-input,
body .numberRenderer span.iconCurrency {
  border-color: var(--global-light-color-1) !important;
}

body .autocompleteRenderer iu-prime-autocomplete span.p-autocomplete .p-autocomplete-input,
body .autocompleteRenderer i.icon-search-bolder,
body .calendarRenderer span.p-calendar.p-calendar-w-btn input,
body .calendarRenderer .iconeCalendar,
body .numberRenderer input.iu-number-input,
body .numberRenderer span.iconCurrency {
  height: 26px !important;
}
body .calendarRenderer span.p-calendar.p-calendar-w-btn input {
  float: left;
}

body .autocompleteRenderer .p-autocomplete-panel {
  top: 0;
}
body div.p-component.p-autocomplete-panel {
  min-width: 150px;
}
.ag-side-button-button {
  min-height: 24px !important;
  padding-top: 8px 0px 5px 0px !important;
}

.ag-side-buttons {
  padding-top: 0 !important;
  background-color: var(--grid-tab-bg-color-side-buttons);
}

.iupics-badge {
  position: absolute;
  z-index: 9;
  height: fit-content;
  padding-left: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 2px;
  border-radius: var(--iupics-badge-br);
  line-height: 10px;
  margin-left: 3px;
  &-shift {
    font-family: var(--font-family);
    border: 1px solid var(--iupics-badge-bd-color-shift);
    background-color: var(--iupics-badge-bg-color-shift);
    color: var(--iupics-badge-ft-color-shift);
  }
  &-not-shift {
    font-family: var(--font-family);
    border: 1px solid var(--iupics-badge-bd-color-not-shift);
    background-color: var(--iupics-badge-bg-color-not-shift);
    color: var(--iupics-badge-ft-color-not-shift);
  }
}

.iupics-badge-legend {
  position: fixed;
  z-index: 9;
  left: 5px;
  bottom: 5px;
  font-family: var(--font-family);
  font-size: 12px;
  padding-left: 4px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
  & > span {
    margin-bottom: 1px;
  }
}
.iupics-badge-count {
  padding-left: 2px;
  padding-right: 2px;
  background-color: var(--iupics-badge-bg-color-count);
  color: var(--iupics-badge-ft-color-count);
  font-size: 8px;
  z-index: 8;
  -webkit-border-radius: var(--iupics-badge-br-count);
  -moz-border-radius: var(--iupics-badge-br-count);
  border-radius: var(--iupics-badge-br-count);
  width: 11px;
  height: 11px;
}

.custom-progressbar {
  border: solid 1px var(--progress-bar-bd-color);
  &.p-progressbar-indeterminate .p-progressbar-value:before {
    background-color: var(--progress-bar-bg-color-indeterminate);
  }
  &.p-progressbar-determinate .p-progressbar-value {
    background-color: var(--progress-bar-bg-color-determinate);
  }
}
body > iu-root > div > iu-desktop-ui > iu-workspace-ui > iu-menu-top-ui > p-toolbar > div:after,
body > iu-root > div > iu-desktop-ui > iu-workspace-ui > iu-menu-top-ui > p-toolbar > div:before {
  display: unset !important;
}

.ag-header-row > .ag-header-cell.ag-header-cell-sortable {
  padding-left: 10px;
}
.loading-progressbar {
  height: 0.15em;
  &.p-progressbar-indeterminate .p-progressbar-value:before {
    background-color: var(--progress-bar-bg-color-indeterminate);
  }
  &.p-progressbar-determinate .p-progressbar-value {
    background-color: var(--progress-bar-bg-color-determinate);
  }
}

/*#region tree-maintenance*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tree-maintenance-spinner-loading.fas.fa-circle-notch {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.tree-maintenance-row-is-summary {
  background-color: var(--tree-bg-color-p-tree-maintenance-row-is-summary) !important;
  color: var(--tree-ft-color-p-tree-maintenance-row-is-summary) !important;
  &:hover {
    background-color: var(--tree-bg-color-p-tree-maintenance-row-is-summary-hover) !important;
  }
}

/*#endregion*/

// #region correction pour compatibilité primeNG 10

.apiz-p-grid {
  display: flex;
  flex-wrap: wrap;
  // align-content: baseline;
  align-items: flex-end;

  & > [class*='p-col'] {
    box-sizing: border-box;
  }
}

.p-overlaypanel {
  background: var(--overlay-panel-bg-color);
  color: var(--overlay-panel-ft-color);
  border: 0 none;
  border-radius: var(--overlay-panel-br);
  box-shadow: var(--overlay-panel-bs);

  .p-overlaypanel-content {
    padding: 1rem 1rem 3rem 1rem;
  }
}

.p-sidebar-right > .p-sidebar-content {
  height: 100%;
}

.p-col-nopad {
  flex: 0 0 auto;
  padding: 0 0.5rem;
}
.p-col-nopadding {
  flex: 0 0 auto;
  padding: 0;
}

.ui-shadow {
  box-shadow: var(--ui-shadow-bs);
}

input {
  &[type='search'] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

// #endregion

// #region quick-toast

.p-toast.quick-toast {
  width: 200px;

  & .p-toast-summary {
    font-size: 16px;
    font-weight: bold;
    margin: 2px 5px;
    border-bottom: solid 1px var(--p-toast-bd-color-quick-toast-summary);
    text-align: center;
  }

  & .p-toast-detail {
    margin: 2px 5px;
    font-size: 13px;
    text-align: center;
  }
}

// #endregion

// #region context-menu override
.p-menuitem-contextmenu {
  cursor: pointer;
}
.p-menuitem-contextmenu:hover {
  background: var(--p-menuitem-contextmenu-bg-color-hover);
}
// #endregion

/* component: usearch-contrainer preview */
.usearch-file-preview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  @at-root body .p-overlaypanel .p-overlaypanel-content {
    height: 100%;
    padding-bottom: 1em;
  }
}

// Champs obligatoire vide
.iu-field-mandatory:not(:read-only)[value=''],
.iu-field-mandatory:not(:read-only):not([value]) {
  color: var(--empty-mandatory-ft-color);
  background-color: var(--empty-mandatory-bg-color);
  border: solid 1px var(--empty-mandatory-bd-color);
  &:hover,
  &:hover ~ * {
    color: var(--empty-mandatory-ft-color-hover);
    border-color: var(--empty-mandatory-bd-color-hover);
  }
}

.iu-field-mandatory:not(:read-only)[value=''] ~ i,
.iu-field-mandatory:not(:read-only):not([value]) ~ i {
  color: var(--empty-mandatory-ft-color);
  background-color: var(--empty-mandatory-bg-color);
  border-right: solid 1px var(--empty-mandatory-bd-color);
  border-top: solid 1px var(--empty-mandatory-bd-color);
  border-bottom: solid 1px var(--empty-mandatory-bd-color);
  border-left: 0;
  &:hover {
    color: var(--empty-mandatory-ft-color-hover);
    background-color: var(--empty-mandatory-bg-color-hover);
    // border-color: transparent; // var(--empty-mandatory-bd-color-hover);
  }
}
.input-text-html-body-mandatory .ql-editor.ql-blank[contenteditable='true'] {
  color: var(--empty-mandatory-ft-color);
  background-color: var(--empty-mandatory-bg-color);
  border: solid 1px var(--empty-mandatory-bd-color);
  &:hover,
  &:hover ~ * {
    color: var(--empty-mandatory-ft-color-hover);
    border-color: var(--empty-mandatory-bd-color-hover);
  }
}

// Champs obligatoire non vide
.iu-field-mandatory:not(:read-only)[value]:not([value='']) {
  color: var(--filled-mandatory-ft-color);
  background-color: var(--filled-mandatory-bg-color);
  border: solid 1px var(--filled-mandatory-bd-color);
  &:hover,
  &:hover ~ i {
    color: var(--filled-mandatory-ft-color-hover);
    border-color: var(--filled-mandatory-bd-color-hover);
  }
}

.iu-field-mandatory:not(:read-only)[value]:not([value='']) ~ i {
  color: var(--filled-mandatory-ft-color);
  background-color: var(--filled-mandatory-bg-color);
  border-right: solid 1px var(--filled-mandatory-bd-color);
  border-top: solid 1px var(--filled-mandatory-bd-color);
  border-bottom: solid 1px var(--filled-mandatory-bd-color);
  border-left: 0;
  &:hover {
    color: var(--filled-mandatory-ft-color-hover);
    background-color: var(--filled-mandatory-bg-color-hover);
    // border-color: transparent; // var(--filled-mandatory-bd-color-hover);
  }
}

.p-disabled,
.p-disabled * {
  // Override PrimeNG behavior
  pointer-events: unset;
}

// input autofill
input:-internal-autofill-selected {
  -webkit-text-fill-color: var(--input-autofill-ft-color) !important;
}

.iu-field-only-label {
  opacity: 0;
}

.user-profile-sidebar .p-sidebar-header {
  padding: 1em 1em 0 0;
}

/* Loading - Linked to the loading directive */
[data-loading='true'] {
  &:not([data-loading-svg='true']) {
    background-color: #eee;
    background-image: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: var(--loading-border-radius, var(--global-border-radius));
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

    width: var(--loading-width, 100%) !important;
    height: var(--loading-height, 100%) !important;

    /* Hide potential text or element visible */
    color: transparent !important;
    border-color: transparent !important;
    * {
      opacity: 0 !important;
    }
  }
  &[data-loading-svg='true'] {
    position: relative;
    width: var(--loading-width, 100%) !important;
    height: var(--loading-height, 100%) !important;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      mask-image: var(--svg-ghost-url, '');
      -webkit-mask-image: var(--svg-ghost-url, '');
      color: transparent !important;
      border-color: transparent !important;
      * {
        opacity: 0 !important;
      }
    }
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.wd-menu-top-show {
  .p-toolbar-group-center {
    flex-grow: 1;
    display: flex;
    margin-top: 0px;
    height: 100%;
    padding-top: 0.7em;
  }

  @media (max-width: 480px) {
    .p-toolbar-group-center {
      display: none;
    }
  }
}

.ag-checkbox-input-wrapper {
  font-size: var(--ag-icon-size);
}

$base-color: var(--ghost-bg-color);
$ghost-color: var(--ghost-bg-color-anime);
$animation-duration: 1000ms;
$gradient-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $ghost-color 40px, $base-color 80px);
  background-size: 80vw;
}

@keyframes ghost-lines {
  0% {
    background-position: -100px;
  }
  40% {
    background-position: 40vw;
  }
  100% {
    background-position: 60vw;
  }
}

@keyframes ghost-avatar {
  0% {
    background-position: -100px + $gradient-offset;
  }
  40%,
  100% {
    background-position: 140px + $gradient-offset;
  }
}

@mixin animate-ghost-avatar {
  @include background-gradient;
  animation: ghost-avatar $animation-duration infinite linear;
}

@mixin animate-ghost-line {
  animation: ghost-lines $animation-duration infinite linear;
}

.ghost {
  background-color: var(--ghost-bg-color);
  &.label {
    color: var(--ghost-ft-color);
    border-radius: var(--menu-br-ghost);
    font-size: 14px;
    @include background-gradient;
    @include animate-ghost-line;
  }
}

.ghost-elt {
  cursor: default;

  & .ghost-round {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: 50%;
    @include background-gradient;
    @include animate-ghost-line;
  }

  & .ghost-rect {
    border-radius: var(--ghost-br);
    @include background-gradient;
    @include animate-ghost-line;
  }

  & [class^='icon-'],
  [class*=' icon-'] {
    background-color: var(--ghost-bg-color);
    color: var(--ghost-ft-color);
    &::before {
      color: var(--ghost-ft-color);
    }
  }
}

/* Style apiz-grid */
.apiz-grid-panel {
  --az-panel-z-index: 10000;
}
.apiz-grid-panel apiz-filters.filters {
  --az-panel-filters-width: min(100vw, 620px);
}
.apiz-grid-applied-item-dropdown {
  --az-applied-item-dropdown-z-index: 10000;
}
